<template>
  <div class="footer flex-all-center-col" id="contact">
    <div class="footer_cont flex-row-between">
      <div class="footer_left">
        <dl>
          <dt>
            <p>联系我们</p>
            <p>官方邮箱</p>
            <p></p>
          </dt>
          <dd>nuannuan@haofan.co</dd>
        </dl>
      </div>
      <div class="footer_right">
        <ul class="flex-row-between">
          <li
            v-for="(item, index) in footerList"
            :key="index"
            class="flex-all-center-col"
          >
            <van-image
              :src="item.url"
              width="179"
              height="179"
              fit="cover"
            ></van-image>
            <span>{{ item.desc }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer_other">
      郑州好返伙伴科技有限公司 豫ICP备2023007206号-1
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arrowLeft:
        "https://images.haofanhuoban.com/decoration/a3b01e610602fff433a72a90f858e220.png",
      arrowRight:
        "https://images.haofanhuoban.com/decoration/09dc51c6099867afce2bdba6bc909bfb.png",

      footerList: [
        {
          url: "https://images.haofanhuoban.com/decoration/02bbcbc6dd0ee2194e90f98cf0663c29.jpg",
          desc: "Andriod下载",
        },
        {
          url: "https://images.haofanhuoban.com/decoration/02bbcbc6dd0ee2194e90f98cf0663c29.jpg",
          desc: "IOS下载",
        },
        {
          url: "https://images.haofanhuoban.com/decoration/b273c2faa6ccf242937ec89b4589920f.jpg",
          desc: "客服微信",
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.footer {
  background-color: #333333;
  width: 100%;
  height: 420px;
  .footer_cont {
    width: 73vw;
    .footer_left {
      dl {
        padding-right: 4vw;
        color: #fff;
        dt {
          p {
            &:nth-child(1) {
              font-weight: 800;
              font-size: 40px;
              line-height: 1;
            }
            &:nth-child(2) {
              font-size: 24px;
              line-height: 1;
              margin-top: 18px;
            }
            &:nth-child(3) {
              width: 75px;
              height: 7px;
              background: #fff;
              border-radius: 4px;
              margin-top: 18px;
            }
          }
        }
        dd {
          height: 58px;
          font-weight: 500;
          font-size: 30px;
          line-height: 39px;
          margin-top: 30px;
        }
      }
    }
    .footer_right {
      li {
        margin-left: 55px;
        color: #fff;
        font-size: 24px;
        &:first-child {
          margin-left: 0;
        }
        span {
          margin-top: 20px;
          font-weight: 500;
        }
      }
    }
  }
  .footer_other {
    width: 73vw;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: #fff;
    position: relative;
    top:40px
  }
}
</style>
