<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {}
  },
  mounted() {
    window.sessionStorage.setItem("isMobile", this.isMobile() - 0)
    // console.log(this.isMobile(), window.sessionStorage.getItem("isMobile")-0)
  },
  methods: {
    init() {},
    isMobile() {
      // 判断是否为移动设备
      return (
        typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上一般存在
        navigator.userAgent.indexOf("IEMobile") !== -1 || // 判断是否为Windows Phone
        navigator.userAgent.indexOf("iPhone") !== -1 || // 判断是否为iPhone
        (navigator.userAgent.indexOf("Android") !== -1 &&
          navigator.userAgent.indexOf("Mobile") !== -1) || // 判断是否为Android手机
        navigator.userAgent.indexOf("BlackBerry") !== -1 || // 判断是否为BlackBerry
        navigator.userAgent.indexOf("Opera Mini") !== -1 // 判断是否为Opera Mini浏览器
      )
    },
  },
}
</script>
<style>
#app {
  overflow-x: hidden;
}
</style>
