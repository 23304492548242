// router.js
import { createRouter, createWebHistory } from 'vue-router';

// 引入组件
import index from './views/index.vue'
import test from './views/test.vue'
// 定义路由
const routes = [
    {
        path: '/',
        component: index,
    }, {
        path: '/index',
        component: index,
    }, {
        path: '/test',
        component: test,
    },
];

// 创建router实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;