import { createApp } from 'vue';
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
import { Swipe, SwipeItem, Button, Sticky, Image as VanImage } from 'vant';
import "vant/lib/index.css";
import VueClipboard from 'vue-clipboard2'
// import "vant/lib/icon/local.css";
// import "@vant/touch-emulator";

import router from './router';
import './assets/css/common.css'
import './assets/css/style.css'


const app = createApp(App);

app.use(router);
app.use(ArcoVue);
app.use(Swipe).use(SwipeItem).use(VanImage).use(Button).use(Sticky);
app.use(VueClipboard)
app.mount('#app');


