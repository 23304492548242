<template>
  <div>
    <a-affix :offsetTop="0" :container="container">
      <van-button type="primary">基础用法</van-button>
    </a-affix>
    <div :style="{ height: '800px' }"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      container: null,
    }
  },
  mounted() {
    this.container = this.$refs.container
  },
}
</script>
