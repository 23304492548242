<template>
    <div class="wrapper flex-all-center flex-col" id="home">
      <headerItem />
  
      <bannerItem /><!-- 轮播图 48vw== 926/1920-->
      <aboutItem /><!-- 生活场景全方位覆盖 -->
  
      <guideItem id="guide" /><!-- 高佣不怕比 -->
  
      <footerItem id="contact" />
    </div>
  </template>
  <script>
  import bannerItem from "@/components/banner"
  import footerItem from "@/components/footer"
  import headerItem from "@/components/header"
  import guideItem from "@/components/guide"
  import aboutItem from "@/components/about"
  export default {
    components: { bannerItem, footerItem, guideItem, aboutItem, headerItem },
    data() {
      return {}
    },
    mounted() {},
    methods: {},
  }
  </script>
  <style scoped lang="scss">
  .wrapper {
    background: #fff;
  
    :deep(.arco-image-img) {
      width: 100% !important;
    }
  }
  </style>
  