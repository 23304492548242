<template>
  <van-image
    width="73vw"
    :preview="false"
    :src="lifeCoverage"
    :style="{ paddingTop: '56px', position: 'realtive' }"
    alt="生活场景全方位覆盖"
  >
    <div id="about" :style="{ position: 'absolute', top: '-62px', opacity: 0 }">
      1
    </div>
  </van-image>
  <van-image
    width="100%"
    :preview="false"
    :src="allPreferential"
    id="about"
    :style="{ paddingTop: '53px' }"
    alt="打造多方共赢的优质互联网生态"
  />
</template>
<script>
export default {
  data() {
    return {
      lifeCoverage:
        "https://images.haofanhuoban.com/decoration/a9ec8c4b99f4643a54e8d2a7cec01871.png", // 生活场景
      allPreferential:
        "https://images.haofanhuoban.com/decoration/fa9f6fb3ce76e5a77042b90418dab872.png", //打造多方共
    }
  },
}
</script>
