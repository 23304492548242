<template>
  <a-affix :offsetTop="0">
    <div class="header_wrap">
      <div class="header_cont flex-row-between">
        <div class="logo_wrap flex-all-center">
          <van-image :src="logo" :preview="false" width="74px" height="74px" />
          <div class="text">网购怕买贵<br />上好返伙伴</div>
        </div>
        <a-anchor line-less :offsetTop="0">
          <a-anchor-link
            :href="'#' + item.href"
            v-for="(item, index) in header"
            :key="index"
            >{{ item.title }}</a-anchor-link
          >
        </a-anchor>
      </div>
    </div>
  </a-affix>
</template>
<script>
export default {
  data() {
    return {
      logo: "https://images.haofanhuoban.com/decoration/0b64b222eff7175b7583217f77d196d3.png",
      header: [
        {
          title: "首页",
          href: "home",
        },
        {
          title: "关于好返",
          href: "about",
        },
        {
          title: "省赚指南",
          href: "guide",
        },
        {
          title: "联系我们",
          href: "contact",
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
:deep(.arco-affix) {
  .header_wrap {
    background-color: rgba($color: #000000, $alpha: 0.2);
    .logo_wrap,
    .text,
    .arco-anchor-link-item .arco-anchor-link {
      color: #ffffff !important;
    }
    .arco-anchor-link-active {
      .arco-anchor-link {
        color: #6c16e9 !important;
      }
    }
  }
  .logo_wrap,
  .text,
  :deep(.arco-anchor-link-item .arco-anchor-link) {
    color: #ffffff !important;
  }
}
.logo_wrap,
.text,
.header_wrap {
  transition: all 0.2s;
}
.header_wrap {
  height: 118px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.header_cont {
  padding: 22px 0;
  width: 75vw;
  margin: 0 auto;

  .logo_wrap {
    color: #333;
    font-size: 20px;
    .text {
      margin-left: 13px;
      line-height: 27px;
    }
  }
  :deep(.arco-anchor) {
    width: auto;
  }
  :deep(.arco-anchor-list) {
    display: flex;
    width: 600px;
    justify-content: space-between;
  }
  :deep(.arco-anchor-link-item .arco-anchor-link) {
    font-size: 20px;
    color: #333;
    &:hover {
      background: none !important;
    }
  }
  :deep(.arco-anchor-link-active) {
    .arco-anchor-link {
      background: none !important;
      color: #6c16e9 !important;
      font-weight: 400;
      font-size: 20px;
    }
  }
}
</style>
