<template>
  <div class="mobile">
    <a-affix :offsetTop="0">
      <div class="header_wrap">
        <div
          class="header_cont flex-row-between"
          @click="menuOpen = !menuOpen"
          :style="{ background: menuOpen ? '#fff' : 'transparent' }"
        >
          <div class="logo_wrap flex-all-center">
            <van-image
              :src="logo"
              :preview="false"
              width="34px"
              height="34px"
            />
            <div
              class="text"
              :style="{ color: menuOpen ? '#333!important' : '' }"
            >
              网购怕买贵<br />上好返伙伴
            </div>
          </div>
          <div
            class="navbar-control"
            :class="{ navbar_control_menu_open: menuOpen == true }"
          >
            <span class="control-icon"></span>
            <span class="control-icon"></span>
            <span class="control-icon"></span>
          </div>
        </div>
        <div
          class="navBar_cont"
          :class="{ navBar_cont_open: menuOpen == true }"
        >
          <dl @click="onDownApp()"><dt>下载APP</dt></dl>
          <dl>
            <dt><a href="#about">关于好返</a></dt>
          </dl>
          <dl>
            <dt><a href="#guide">省赚指南</a></dt>
          </dl>
          <dl>
            <dt>联系我们</dt>
            <dd
              class="flex-row-between"
              @click="onCopyText('nuannuan@haofan.co')"
              :style="{ marginTop: '-10px' }"
            >
              <label>官方邮箱</label><span>nuannuan@haofan.co ></span>
            </dd>
            <dd class="flex-row-between" @click="onService()">
              <label>客服微信</label><span>立即咨询 ></span>
            </dd>
            <dd></dd>
          </dl>
        </div>
      </div>
    </a-affix>

    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index"
          ><van-image :src="item.url" width="100%"></van-image
        ></van-swipe-item>
      </van-swipe>
    </div>
    <div class="about">
      <div
        id="about"
        :style="{ position: 'absolute', top: 'calc(-50px )', opacity: 0 }"
      >
        1
      </div>
      <van-image
        width="100%"
        :preview="false"
        :src="lifeCoverage"
        :style="{ padding: '18px 10px 14px 10px', position: 'realtive' }"
        alt="生活场景全方位覆盖"
      >
        <div
          id="about"
          :style="{ position: 'absolute', top: '-62px', opacity: 0 }"
        >
          1
        </div>
      </van-image>
      <van-image
        width="100%"
        :preview="false"
        :src="allPreferential"
        id="about"
        alt="打造多方共赢的优质互联网生态"
      />
    </div>
    <div class="guide flex-all-center-col">
      <div
        id="guide"
        :style="{ position: 'absolute', top: '-50px', opacity: 0 }"
      >
        1
      </div>
      <dl class="text-center">
        <dt class="c232323">省赚指南·高佣不怕比</dt>
        <dd class="c333">
          好返伙伴app把优惠券、折扣、代金券、下单返利等优惠融入到人们日常生活所需场景中，让消费者下单优惠省钱，分享简单赚钱。
        </dd>
      </dl>
      <van-swipe :autoplay="false" ref="guide_banner">
        <van-swipe-item v-for="(item, index) in videoList" :key="index">
          <video
            :style="{
              width: '300px',
              height: '169px',
              background: '#000000',
            }"
            :controls="true"
            muted
            preload
            :ref="'video_' + index"
            :key="index"
            :allowfullscreen="true"
            :poster="item.coverUrl"
          >
            <source :src="item.url" type="video/mp4" />
            您的浏览器不支持 HTML5 video 标签。
          </video>
          <p class="video_desc">{{ item.desc }}</p>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="footer">
      <dl>
        <dt>联系我们</dt>
        <dd>
          <label for="">官方邮箱</label
          ><span @click="onCopyText('nuannuan@haofan.co')"
            >nuannuan@haofan.co ></span
          >
        </dd>
        <dd>
          <label for="">客服微信</label
          ><span @click="onService()">立即咨询 ></span>
        </dd>
        <dd>
          <label for="">好返伙伴APP</label
          ><span @click="onDownApp()">立即下载 ></span>
        </dd>
      </dl>
      <p>郑州好返伙伴科技有限公司<br />豫ICP备2023007206号-1</p>
    </div>
    <div class="footer_fixed">
      <van-button
        color="linear-gradient(90deg, #D914FC, #7C0BF5)"
        @click="onDownApp()"
        ><span>下载好返APP</span></van-button
      >
    </div>
  </div>
</template>
<script>
import { showSuccessToast, showFailToast } from "vant"
export default {
  data() {
    return {
      menuOpen: false,
      logo: "https://images.haofanhuoban.com/decoration/0b64b222eff7175b7583217f77d196d3.png",
      bannerList: [
        {
          url: "https://images.haofanhuoban.com/decoration/b87ed715e32a52a32955ca2b48b13e80.png",
        },
        {
          url: "https://images.haofanhuoban.com/decoration/83b0874c403779b654364b3a291ae715.png",
        },
      ],
      lifeCoverage:
        "https://images.haofanhuoban.com/decoration/c1561afded309aa60ee3c44e74d7aef6.png", // 生活场景
      allPreferential:
        "https://images.haofanhuoban.com/decoration/e0311a74512579896cc1103493ca5fc6.png", //打造多方共
      videoList: [
        {
          url: "https://images.haofanhuoban.com/decoration/c9d35022d5fde444c8f9cd3565c6ab75.mp4",
          coverUrl:
            "https://images.haofanhuoban.com/decoration/5cfafb016f558a67820f0ad81734df06.png",
          desc: "一分钟快速了解【好返伙伴】",
        },
        {
          url: "https://images.haofanhuoban.com/decoration/c6997a154807dc5aa6d74cf980f3cad8.mp4",
          coverUrl:
            "https://images.haofanhuoban.com/decoration/74c992fdd2273cca7500ed4da6856b48.png",
          desc: "如何用好返APP省钱购物？",
        },
      ],
    }
  },
  watch: {
    menuOpen(newV) {
      var element = document.getElementById("html")
      console.log(element.style)
      if (newV) {
        element.style.overflowY = "hidden"
      } else {
        element.style.overflowY = "auto"
      }
    },
    $route() {
      // 关于好返、省赚指南关闭导航
      this.menuOpen = false
    },
  },
  methods: {
    onService() {
      this.menuOpen = false
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc6edc1ec1c848efa9"
    },
    /* 下载APP */
    onDownApp() {
      this.menuOpen = false
      let appDownloadUrl =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.haofan.mow"
      let appDownloadUrlIos =
        "https://apps.apple.com/cn/app/好返伙伴/id6503635594"

      location.href =
        this.checkPlatform() == "ios" ? appDownloadUrlIos : appDownloadUrl
      return
    },
    onCopyText(text) {
      this.menuOpen = false
      this.syncCopyText(() => {
        this.$copyText(text)
          .then(() => {
            showSuccessToast("已复制官方邮箱")
          })
          .catch(() => {
            showFailToast("复制失败，请重试")
          })
      })
    },
    /* IOS H5 复制 复制口令 复制失败处理方法 */
    syncCopyText(func) {
      console.log("H5复制")
      let timer = setTimeout(async () => {
        await func()
        clearTimeout(timer)
      }, 0)
    },
    checkPlatform() {
      var ua = navigator.userAgent
      if (ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1) {
        return "android"
      }
      if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        return "ios"
      }
    },
  },
}
</script>
<style scoped lang="scss">
.mobile {
  min-height: 100vh;
  height: 100%;
  .van-image {
    display: block;
  }
  :deep(.van-sticky--fixed),
  :deep(.arco-affix) {
    .header_wrap {
      background-color: rgba($color: #000000, $alpha: 0.5);
      .text {
        color: #ffffff !important;
      }
    }
    .control-icon {
      &:before {
        background-color: #ffffff !important;
      }
    }
  }
  .text,
  .header_wrap {
    transition: all 0.5s;
  }
  .header_wrap {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    .header_cont {
      // padding: 0 24px;
      padding: 0px 18px 0px 24px;
      height: 50px;

      .logo_wrap {
        .text {
          margin-left: 7px;
          line-height: 1.4;
          font-size: 12px;
          font-weight: 500;
          color: #333;
        }
      }
      .navbar-control {
        padding: 0;
        -webkit-tap-highlight-color: #333;
        .control-icon {
          display: block;
          padding-top: 3px;
          padding-bottom: 3px;
          transition: all 0.4s ease;
          &:before {
            content: "";
            display: block;
            height: 2px;
            width: 22px;
            background-color: #999999;
            border-top-right-radius: 2px;
            border-bottom-left-radius: 2px;
            transition: transform 0.4s ease 0.2s, background-color 0.5s ease 0s;
          }
        }
      }
      .navbar_control_menu_open {
        .control-icon {
          &:before {
            background-color: #2c2c2c !important;
          }
          &:nth-child(1) {
            transform: translateY(8px);
            &:before {
              transform: rotate(45deg);
            }
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-8px);
            &:before {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .navBar_cont {
      background-color: #fff;
      transition: height 1s ease;
      padding: 0 25px;
      border-top: none;
      height: 0;

      dl {
        transition: all 0.3s ease;
        opacity: 0;
        display: none;
        &:first-child {
          margin-top: 12px;
        }
        dt {
          font-size: 15px;
          color: #333;
          line-height: 52px;
          &:hover:not-child(:last-child) {
            color: #6c16e9;
          }
        }
        dd {
          font-size: 13px;
          color: #999;
          line-height: 24px;
          padding-left: 15px;
        }
      }
      &.navBar_cont_open {
        border-top: 1px solid #dadada;
        height: calc(100vh - 50px);
        overflow: auto;
        overflow-x: hidden;
        dl {
          opacity: 1;
          display: block;
        }
      }
    }
  }
  .banner {
  }
  .about {
    position: relative;
  }
  .guide {
    height: 383.5px;
    padding: 0 38px;
    text-align: center;
    position: relative;
    dl {
      text-align: center;
      padding: 0 6px;
      margin-bottom: 10px;
      dt {
        font-weight: 500;
        font-size: 15px;
        color: #232323;
        line-height: 24px;
      }
      dd {
        font-size: 11px;
        color: #333333;
        line-height: 20px;
        margin-top: 15px;
      }
    }
    .van-swipe,
    .van-swipe-item {
      width: 300px;
    }
    .video_desc {
      height: 64px;
      font-weight: 400;
      font-size: 11px;
      color: #666666;
      line-height: 20px;
      padding: 20px 0 17px 0;
    }
  }
  .footer {
    height: 318px;
    background: #333333;
    padding: 24px;
    dl {
      dt {
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        line-height: 1;
        margin-bottom: 27px;
        margin-top: 6px;
      }
      dd {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        line-height: 1;
        font-weight: 500;
        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
    p {
      line-height: 17px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      margin-top: 40px;
      font-weight: 500;
    }
  }
  .footer_fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
    .van-button {
      width: 200px;
      height: 40px;
      border-radius: 9px;
      font-size: 13px;
    }
  }
  // :deep(.van-swipe__indicators) {
  //   bottom: 0;
  // }
  :deep(.van-swipe__indicator) {
    width: 13px;
    height: 3px;
    background: #d0d0d0;
    border-radius: 3px;
  }
  :deep(.van-swipe__indicator--active) {
    background: linear-gradient(180deg, #d914fc, #7c0bf5);
  }
}
</style>
