<template>
  <a-carousel
    :style="{
      width: '100%',
      height: '48vw',
    }"
    auto-play="hoverToPause"
    :move-speed="500"
    indicator-type="line"
    transition-timing-function="none"
    class="banner"
  >
    <a-carousel-item v-for="(item, index) in bannerList" :key="index">
      <van-image
        width="100%"
        height="100%"
        :preview="false"
        :src="item.url"
        fit="cover"
      />
    </a-carousel-item>
  </a-carousel>
</template>
<script>
export default {
  data() {
    return {
      bannerList: [
        {
          url: "https://images.haofanhuoban.com/decoration/bdb6fc87fb92bd1095dad527f9762753.png",
        },
        {
          url: "https://images.haofanhuoban.com/decoration/59e7f46ffc94823555d7fe2a362d6c33.png",
        },
      ],
    }
  },
  mounted() {},
  methods: {
    
  },
}
</script>
<style scoped lang="scss">
.banner {
  :deep(.arco-carousel-indicator-bottom) {
    top: 12px !important;
  }
  :deep(.arco-carousel-indicator-wrapper-bottom) {
    background: none;
  }
  :deep(.arco-carousel-indicator-item) {
    width: 32px;
    height: 6px;
    border-radius: 3px;
    background: #d0d0d0;
  }
  :deep(.arco-carousel-indicator-item-active) {
    background: linear-gradient(180deg, #d914fc, #7c0bf5);
  }
  :dep(.arco-carousel-arrow) {
    display: none;
  }
}
</style>
