<template>
  <pcItem v-if="isMobile == 0"></pcItem>
  <mobileItem v-else></mobileItem>
</template>
<script>
import pcItem from "../views/pc/index"
import mobileItem from "../views/mobile/index"
export default {
  components: {
    pcItem,
    mobileItem,
  },
  data() {
    return {
      isMobile: 0,
    }
  },
  created() {
    this.handleResize()
    window.addEventListener("resize", () => this.handleResize())
  },
  methods: {
    handleResize() {
      this.isMobile = window.sessionStorage.getItem("isMobile") - 0
      if (window.innerWidth < 700) {
        this.isMobile = 1
      } else {
        this.isMobile = 0
      }
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize)
  },
}
</script>
<style scoped lang="scss"></style>
